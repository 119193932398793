import { render, staticRenderFns } from "./subgroups_and_projects_empty_state.vue?vue&type=template&id=3ce7e7ff&"
import script from "./subgroups_and_projects_empty_state.vue?vue&type=script&lang=js&"
export * from "./subgroups_and_projects_empty_state.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports